<template>
  <div class="livechat-page">
    <div class="livechat-container">
      <div class="contactlist-container">
        <div class="search-box">
          <div class="flex-fill">
            <b-dropdown
              variant="outline-dark"
              left
            >
              <template v-slot:button-content>
                <template v-if="statusFilter === 'Broadcast'">
                  <i class="uil uil-envelope-redo"></i> Broadcast
                </template>
                <template v-else-if="statusFilter === 'Closed'">
                  <i class="uil uil-envelope-block"></i> Closed
                </template>
                <template v-else-if="statusFilter === 'Unread'">
                  <i class="uil uil-envelope-add"></i> Unread
                </template>
                <template v-else>
                  <i class="uil uil-envelope-alt"></i> Open 
                </template>
                
                <i class="uil uil-angle-down"></i>
              </template>

              <b-dropdown-item href="#" @click="handleChangeFilter(null)">
                <i class="uil uil-envelope-alt"></i> Open
              </b-dropdown-item>

              <b-dropdown-item href="#" @click="handleChangeFilter('Broadcast')">
                <i class="uil uil-envelope-redo"></i> Broadcast
              </b-dropdown-item>

              <b-dropdown-item href="#" @click="handleChangeFilter('Unread')">
                <i class="uil uil-envelope-add"></i> Unread
              </b-dropdown-item>

              <b-dropdown-item href="#" @click="handleChangeFilter('Closed')">
                <i class="uil uil-envelope-block"></i> Closed
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <button
            size="sm"
            class="btn"
            :class="{ 'btn-primary': !isShowNewContact, 'btn-danger': isShowNewContact }"
            @click="showNewModal"
          >
            <i v-if="isShowNewContact" class="uil uil-times"></i>
            <i v-else class="uil uil-plus"></i>
          </button>
        </div>
        <div
          ref="listUsersWrapper"
          class="list-users-wrapper"
          @scroll.passive="onContactScroll"
        >
          <ul v-if="contacts.length" class="list-users">
            <li
              v-for="item in contacts"
              :key="`contact-${item.id}`"
              :class="{ selected: selectedContact.id === item.id }"
              @click="selectContact(item, true)"
            >
              <a class="chat">
                <span v-if="item.status === 'Unread'" class="online"></span>
                <div class="media">
                  <div class="text-center mr-3">
                    <Avatar
                      :username="item.avatar"
                      class="profile-avatar"
                      background-color="#30a952"
                      :size="40"
                    />
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-15 mt-0 mb-1 name">{{ getLabel(item) }}</h5>
                    <template>
                      <p
                        v-if="item.last_message"
                        class="text-muted font-size-13 text-truncate mb-0"
                        style="max-width: 210px"
                        >{{ item.last_message.content }}</p
                      >
                      <span class="time">{{
                        (item.last_message
                          ? item.last_message.created_at
                          : item.created_at) | datetime('MMM DD, hh:mm A')
                      }}</span>
                    </template>
                  </div>
                </div>
              </a>
            </li>
          </ul>
          <div v-else-if="!loadingContacts" class="no-contact-wrapper">
            <div class="d-flex flex-column align-items-center">
              <i class="uil uil-comments"></i>
              <p>No Contacts</p>
            </div>
          </div>
          <div v-if="loadingContacts" class="text-center mb-3 mt-4">
            <b-spinner />
          </div>
        </div>
      </div>

      <div class="chat-container">
        <div class="chat-header">
          <div v-if="isShowNewContact" class="d-flex w-100">
            <div v-if="isLoadingCheckContact" class="mt-2 mr-2">
              <b-spinner small></b-spinner>
            </div>
            <div class="flex-fill">
              <PhoneInput v-model="newContactPhone" margin-bottom="0" @onInputChange="(args) => seachContactDebounce(args)" />
            </div>
            <button v-if="!searchedContacts.length && search && search.isValid" :disabled="loadingCreate"
              class="btn btn-primary ml-2"
              @click="handleCreate">
              <b-spinner v-if="loadingCreate" small />
              <span v-else>Add contact</span>
            </button>
            <div v-if="searchedContacts.length" class="list-searched-contacts">
              <div v-for="contact in searchedContacts" :key="`contact-${contact.id}`" class="search-item" @click="handlePrependContact(contact)">
                <div class="d-flex">
                  <Avatar
                    :username="contact.avatar"
                    class="profile-avatar"
                    background-color="#30a952"
                    :size="40"
                  />
                  <div class="flex-fill search-detail">
                    <h5 class="font-size-15 mt-0 mb-1">{{ getLabel(contact) }}</h5>
                    <p>{{ contact.phone }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-else>
            <template v-if="selectedContact">
              <h4 >{{ selectedContact.label }}</h4>
              <b-button
                v-b-tooltip.hover
                :title="`Make conversation as ${selectedContact.status === 'Closed' ? 'Open' : 'Closed'}`"
                type="button"
                size="sm"
                class="mr-3 btn-detail"
                :disabled="loadingToggleClose"
                @click="handleToggleClose(false)"
              >
                <b-spinner v-if="loadingToggleClose" style="width: 1.5rem; height: 1.5rem;"></b-spinner>
                <i v-else class="uil" :class="{ 'uil-envelope-open': selectedContact.status === 'Closed', 'uil-check': selectedContact.status !== 'Closed' }"></i>
              </b-button>
              <b-button
                v-b-tooltip.hover
                :title="`Make conversation as ${selectedContact.status === 'Unread' ? 'Read' : 'Unread'}`"
                type="button"
                size="sm"
                class="mr-3 btn-detail"
                :disabled="loadingToggleRead"
                @click="handleToggleRead"
              >
                <b-spinner v-if="loadingToggleRead" style="width: 1.5rem; height: 1.5rem;"></b-spinner>
                <template v-else>
                  <i v-if="selectedContact.status === 'Read'" class="uil uil-comment-alt-info"></i>
                  <i v-else class="uil" :class="{ 'uil-comment-alt-check': selectedContact.status === 'Unread', 'uil-comment-alt-message': selectedContact.status !== 'Unread' }"></i>
                </template>
              </b-button>
              <b-button
                v-b-tooltip.hover
                title="Toggle Contact Infomation"
                type="button"
                size="sm"
                class="btn-detail ml-1"
                @click="isShowContactDetail = !isShowContactDetail"
              >
                <i class="uil uil-user"></i>
              </b-button>
            </template>
          </template>
        </div>
        <div class="chat-main">
          <div class="chat-conversation">
            <div
              ref="chatMessages"
              class="chat-messages"
              @scroll.passive="onMessageScroll"
            >
              <div v-if="loadingMessages" class="text-center">
                <b-spinner />
              </div>
              <ul v-if="messages.length" class="conversation-list">
                <li
                  v-for="message in messages"
                  :key="`message-${message.id}`"
                  class="clearfix"
                  :class="{
                    odd: message.sender.role === 'business',
                    broadcast: message.is_broadcast && !disableMessageFormat,
                  }"
                >
                  <div class="chat-avatar">
                    <Avatar
                      :username="message.sender.name"
                      class="profile-avatar"
                      :size="40"
                    />
                  </div>
                  <div class="conversation-text">
                    <div v-b-tooltip.hover class="ctext-wrap" :class="{ 'email': message.provider === 2 && !disableMessageFormat }"  
                      :title="getMessageTitle(message)">
                      <!-- eslint-disable vue/no-v-html -->
                      <p v-if="message.content" v-linkified v-html="formatMessage(message)" />
                      <!--eslint-enable-->
                      <div v-if="isActiveMms">
                        <template v-for="attachment in message.attachments">
                          <img v-if="isImageFile(attachment.file_type)" :key="attachment.id" class="chat-media" :src="attachment.src" @click="handleZoomAttachment(attachment)"/>
                          <a v-else :key="attachment.id" href="javascript:;" class="d-flex py-3 align-items-center" @click="handleZoomAttachment(attachment)">
                            <i class="fa fa-file pr-2" style="font-size: 25px;" aria-hidden="true"></i> {{ attachment.file_name }}
                          </a>
                        </template>
                      </div>
                    </div>
                    <div class="timestamp">
                      <span>{{
                        message.created_at | datetime('MMM DD, hh:mm A')
                      }}</span>
                    </div>
                  </div>
                </li>
              </ul>
              <div v-else-if="!loadingMessages" class="no-message-wrapper">
                <div class="d-flex flex-column align-items-center">
                  <i class="uil uil-comment-message"></i>
                  <p>No Messages</p>
                </div>
              </div>
            </div>
            <form
              v-if="selectedContact"
              class="needs-validation"
              name="chat-form"
            >
              <div class="row">
                <div class="col-12">
                  <b-tabs v-if="isEmailEnabled && allowEmailIntegration" v-model="messageType" class="inbox-message-input">
                    <b-tab title="SMS" active>
                      <TextAreaAction
                        v-model="newMessage.content"
                        name="Responder"
                        margin-bottom="0px"
                        placeholder="Your message..."
                        :sms-length="true"
                        :object="newMessage"
                      />
                    </b-tab>
                    <b-tab title="Email">
                      <TextEditorAction
                        ref="emailInput"
                        v-model="newMessage.content"
                        name="Responder"
                        :object="newMessage"
                        margin-bottom="0px"
                        placeholder="Your Email..."
                      ></TextEditorAction>
                    </b-tab>
                  </b-tabs>
                  <div v-else class="d-block w-100">
                    <TextAreaAction
                      v-model="newMessage.content"
                      name="Responder"
                      margin-bottom="0px"
                      placeholder="Your message..."
                      :sms-length="true"
                      :object="newMessage"
                    />
                  </div>
                </div>
                <div class="col-12 d-flex mt-2">
                  <div class="d-flex flex-fill justify-content-left">
                    <!-- <SMSLength :text="newMessage.content" /> -->
                  </div>
                  <button
                    :disabled="!newMessage.content || loadingSend"
                    class="
                      btn btn-danger
                      chat-send
                      waves-effect waves-light
                      ml-2
                    "
                    @click="handleSend"
                  >
                    <b-spinner v-if="loadingSend" small />
                    <span v-else>Send</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            v-if="isShowContactDetail && selectedContact"
            class="contact-detail"
          >
            <div class="user-detail">
              <Avatar
                :username="selectedContact.avatar"
                class="profile-avatar"
                :size="42"
              />
              <div class="user-info">
                <div class="name">{{ selectedContact.name }}</div>
                <div class="phone">{{
                  selectedContact.phone | nationalNumber
                }}</div>
              </div>
            </div>
            <h4>Contact details</h4>
            <div class="list-attrs">
              <div v-for="(item, index) in editFields" :key="`field-${index}`" class="attr-item" :class="{ saving: item.key === loadingSaveAttr }">
                <strong>{{ item.label }}:</strong> <div class="attr-value" contenteditable :data-holder="selectedContact[item.key] ? '' : item.label" @blur="handleSaveAttr($event, item.key)">{{ selectedContact[item.key] }}</div>
                <b-spinner small/>
              </div>
              <div class="attr-item">
                <strong>Phone:</strong> <div class="attr-value">{{ selectedContact.phone | nationalNumber }}</div>
                <b-spinner small/>
              </div>
              <div class="attr-item mt-2">
                <strong class="pr-2">SMS - Opt Out</strong>
                <b-form-checkbox v-if="selectedContact.twilio_opt_out" v-model="selectedContact.toggle_opt_out"
                  v-b-tooltip.hover
                  title="We can't Opt In this contact as customer unsubscribed on his/her side."
                  name="check-button" switch inline
                  :disabled="!!selectedContact.twilio_opt_out">
                  Opt In
                </b-form-checkbox>
                <b-form-checkbox v-else v-model="selectedContact.toggle_opt_out"
                  name="check-button" switch inline @change="toggleOptOut('sms')">
                  Opt In
                </b-form-checkbox>
              </div>
              <div class="attr-item mt-4">
                <strong class="pr-2">Email - Opt Out</strong>
                <b-form-checkbox v-model="selectedContact.toggle_email_opt_out"
                  name="check-button" switch inline @change="toggleOptOut('email')">
                  Opt In
                </b-form-checkbox>
              </div>
            </div>
            <hr />
            <h5>
              <i class="uil uil-tag-alt"></i> Keywords <span v-if="selectedContact.keywords.length">({{ selectedContact.keywords.length }})</span>
            </h5>
            <div class="list-keywords">
              <div>
              <span class="btn btn-outline-dark btn-xs mb-2 mr-1" @click="onAddKeyword">
                <i class="uil uil-plus"></i> Add keyword
              </span>
              </div>
              <span v-for="(keyword, index) in selectedContact.keywords" :key="`keyword-${keyword.id}`" class="mb-2 keyword-item btn btn-primary btn-xs">
                {{ keyword.name }} <span @click="removeKeyword(index)"><i class="uil uil-times"></i></span>
              </span>
            </div>
            <h5>
              <i class="uil uil-tag-alt"></i> Automations in process
            </h5>
            <div class="list-keywords">
              <div>
              <span class="btn btn-outline-dark btn-xs mb-2 mr-1" @click="handleAddAutomation">
                <i class="uil uil-plus"></i> Add Automation
              </span>
              </div>
              <span v-for="automation in selectedContact.automations" :key="`keyword-${automation.id}`" v-b-tooltip.hover :title="getAutomationTooltip(automation)" class="mb-2 keyword-item btn btn-primary btn-xs">
                {{ automation.name }} <template v-if="automation.process_count > 1">( {{ automation.process_count }} )</template><span @click="onStopAutomation(automation)"><i class="uil uil-times"></i></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="isShowTagModal" title="Keywords" size="lg">
      <div class="row">
        <div class="col-12">
          <div v-if="cloneContact" class="form-group">
            <multiselect
              v-model="cloneContact.keywords"
              :options="keywords"
              :multiple="true"
              track-by="id"
              placeholder="Select keywords"
              label="name"
              :taggable="true"
              @tag="addKeyword"
            ></multiselect>
          </div>
        </div>
      </div>

      <template v-slot:modal-footer>
        <b-button variant="primary" :disabled="loadingSaveKeywords" @click="handleSaveKeywords(cloneContact)">
          <b-spinner v-if="loadingSaveKeywords" small />
          <span v-else>Save</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="isShowAttachmentModal" title="Attachment">
      <div v-if="selectedAttachment" class="row">
        <div class="col-md-12 text-center pb-4">
          <img v-if="isImageFile(selectedAttachment.file_type)" class="img-fluid rounded" :src="selectedAttachment.src">
          <span v-else><i class="fa fa-file" style="font-size: 60px;" aria-hidden="true"></i></span>
        </div>
        <div class="col-md-12">
          <p><b>FileName</b> {{ selectedAttachment.file_name ? selectedAttachment.file_name : 'N/A' }}</p>
          <p><b>FileSize</b> {{ selectedAttachment.file_size ? fileSize(selectedAttachment.file_size) : 'N/A' }}</p>
          <p><b>FileType</b> {{ selectedAttachment.file_type ? selectedAttachment.file_type : 'N/A' }}</p>
        </div>
      </div>
      <template v-slot:modal-footer>
        <a :href="selectedAttachment.src" target="_blank" class="btn btn-success">Download</a>
        <b-button variant="primary" @click="isShowAttachmentModal = false">
          Close
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="isShowAutomationAddModal" title="Run Automation">
      <div class="form-group">
        <label>Automation</label>
        <multiselect
          v-model="selectedAutomation"
          :options="automations"
          :multiple="false"
          track-by="id"
          placeholder="Select automation"
          label="name"
        ></multiselect>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="primary" :disabled="isLoadingStartAutomation || !selectedAutomation" @click="handleStartAutomation">
          <b-spinner v-if="isLoadingStartAutomation" small />
          <span v-else>Start</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="isShowDeleteAutomationModal" title="Stop automation">
      <p v-if="selectedAutomation"
        >Are you sure you want to stop this automation <b>"{{ selectedAutomation.name }}"</b>?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="isShowDeleteAutomationModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" :disabled="isLoadingStopAutomation" @click="handleStopAutomation">
            <b-spinner v-if="isLoadingStopAutomation" small />
            <span v-else>Stop</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {decode} from 'html-entities';
import Pusher from 'pusher-js'
import Avatar from 'vue-avatar'
import Multiselect from 'vue-multiselect'
import debounce from 'lodash.debounce'

export default {
  components: {
    Avatar,
    Multiselect,
  },

  data() {
    return {
      pusherObj: undefined,
      pusherChannel: undefined,
      messages: [],
      isShowContactDetail: true,
      loadingCreate: false,
      contacts: [],
      selectedContact: null,
      newMessage: {
        content: '',
        images: [],
      },
      loadingSend: false,
      loadingContacts: false,
      loadingMessages: false,
      defaultContactPagination: {
        loadMore: true,
        page: 1,
        per_page: 30,
      },
      defaultMessagePagination: {
        loadMore: true,
        page: 1,
        per_page: 20,
      },
      contactPagination: {},
      messagePagination: {},
      isShowTagModal: false,
      keywords: [],
      loadingSaveKeywords: false,
      cloneContact: null,
      isShowNewContact: false,
      isLoadingCheckContact: false,
      seachContactDebounce: null,
      searchedContacts: [],
      search: null,
      newContactPhone: null,
      loadingToggleClose: false,
      loadingToggleRead: false,
      statusFilter: null,
      editAttrName: null,
      editAttrValue: null,
      loadingSaveAttr: null,
      editFields: [
        { label: 'First name', key: 'first_name' },
        { label: 'Last name', key: 'last_name' },
        { label: 'Email', key: 'email' },
        { label: 'Business name', key: 'business_name' },
        { label: 'Website url', key: 'website_url' },
      ],
      selectedAttachment: null,
      isShowAttachmentModal: false,
      isShowAutomationAddModal: false,
      automations: [],
      selectedAutomation: null,
      isLoadingStartAutomation: false,
      isShowDeleteAutomationModal: false,
      isLoadingStopAutomation: false,
      messageType: 0,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    business() {
      return this.user && this.user.business
    },

    agency() {
      return this.business && this.business.agency
    },

    isActiveMms() {
      return this.business && this.business.is_active_mms
    },

    disableMessageFormat() {
      return true
    },
    
    hasSendgridIntegration() {
      return this.user && this.user.business && this.user.business.has_integrations.sendgrid
    },

    hasSesIntegration() {
      return this.user && this.user.business && this.user.business.has_integrations.ses
    },

    isEmailEnabled() {
      return this.hasSendgridIntegration
    },

    allowEmailIntegration() {
      return this.$store.getters['auth/allowEmailIntegration']
    },
  },

  watch: {
    messageType: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.newMessage.content = ''
      }
    },
  },

  created() {
    this.seachContactDebounce = debounce((args) => {
      this.search = args
      this.handleSearchContact()
    }, 700)
  },

  mounted() {
    this.$store
      .dispatch('user/getLiveData')
    if (this.agency && (!this.agency.is_enabled_livechat || !this.business.is_active_livechat)) {
      this.$router.push({ name: 'business.dashboard' })
    } else {
      this.contactPagination = { ...this.defaultContactPagination }
      this.messagePagination = { ...this.defaultMessagePagination }
      
      this.pusherObj = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      })

      this.subscribeChannel()
      this.getContacts()
    }
  },

  beforeDestroy() {
    this.unsubscribeChannel();
    this.$store.commit('livechat/selectContact', undefined);
  },

  methods: {
    formatMessage(item) {
      let content = item.content
      if (item.provider === 2)
        content = decode(content)
      return content
    },
    getMessageTitle(message)
    {
      let res = ''
      let type = 'SMS'
      if (message.provider === 2)
        type = 'Email'
      if (message.is_broadcast)
        type += ' Broadcast'
      res += `Type: ${type}\n`
      return res
    },

    toggleOptOut(type) {
      let param = {
        id: this.selectedContact.id,
        type
      }
      this.$store
        .dispatch('contact/toggleOptOut', param)
        .then((res) => {
          this.selectedContact.opt_out = res.opt_out
          this.selectedContact.email_opt_out = res.email_opt_out
          this.selectedContact.toggle_opt_out = !res.opt_out
          this.selectedContact.toggle_email_opt_out = !res.email_opt_out
        })
        .catch(() => {})
    },
    handleAddAutomation() {
      this.getAutomations()
      this.isShowAutomationAddModal = true
    },

    unsubscribeChannel() {
      if (this.pusherChannel) {
        this.pusherObj.unsubscribe(this.pusherChannel);
      }
    },

    onContactScroll() {
      const contactWrapper = this.$refs.listUsersWrapper
      if (
        contactWrapper && 
        contactWrapper.scrollTop >
          contactWrapper.scrollHeight - contactWrapper.offsetHeight - 10 &&
        !this.loadingContacts
      ) {
        this.getContacts(true)
      }
    },

    onMessageScroll() {
      const messageWrapper = this.$refs.chatMessages
      if (messageWrapper.scrollTop < 10 && !this.loadingMessages) {
        this.getMessages(true);
      }
    },

    getLabel(item) {
      return item.name || this.formatNational(item.phone)
    },

    scrollTop() {
      this.$nextTick(() => {
        if (this.$refs.chatMessages) {
          this.$refs.chatMessages.scrollTop = this.$refs.chatMessages.scrollHeight  
        }
      })
    },

    subscribeChannel() {
      this.pusherChannel = `${process.env.VUE_APP_NOTIFICATION_CHANNEL}${this.user.id}`
      let channel = this.pusherObj.subscribe(this.pusherChannel)
      channel.bind('new-message', ({ message, contact, data }) => {
        if (
          this.selectedContact &&
          this.selectedContact.id === contact.id
        ) {
          if (!this.messages.find((item) => item.id === message.id)) {
            this.messages.push(message)
            this.scrollTop()
          }

          this.$store.dispatch('livechat/setStatus', { contactId: this.selectedContact.id, status: this.selectedContact.status })
            .then(() => {
              this.$store.dispatch('user/updateUnread', data.unread - 1)
            })
        } else {
          let contactIndex = this.contacts.findIndex(c => c.id === contact.id)

          if (contactIndex !== -1) {
            this.contacts.splice(contactIndex, 1);
          }
          this.contacts = [contact, ...this.contacts];
        }
      })
    },

    onReadMessage(contact) {
      contact.status = undefined
      if (this.user && this.user.business)
      {
        this.$store.dispatch('user/updateUnread', this.user.business.unread - 1)
      }
    },

    getAutomations() {
      this.$store.dispatch('automation/all')
        .then(automations => {
          this.automations = automations
        })
        .catch(() => {

        })
    },

    handleStartAutomation() {
      this.isLoadingStartAutomation = true

      this.$store.dispatch('contact/startAutomation', {
        contactId: this.selectedContact.id,
        automationId: this.selectedAutomation.id,
      })
        .then((automations) => {
          this.selectedContact.automations = automations
          this.isShowAutomationAddModal = false
          this.isLoadingStartAutomation = false
          this.selectedAutomation = null
        })
        .catch(() => {
          this.isLoadingStartAutomation = false
        })
    },

    onStopAutomation(automation) {
      this.isShowDeleteAutomationModal = true
      this.selectedAutomation = automation
    },

    handleStopAutomation() {
      this.isLoadingStopAutomation = true

      this.$store.dispatch('contact/stopAutomation', {
        contactId: this.selectedContact.id,
        automationId: this.selectedAutomation.id,
      })
        .then((automations) => {
          this.selectedContact.automations = automations
          this.isShowDeleteAutomationModal = false
          this.isLoadingStopAutomation = false
          this.selectedAutomation = null
        })
        .catch(() => {
          this.isLoadingStopAutomation = false
        })
    },

    selectContact(contact, manual = false) {
      this.selectedContact = contact
      this.selectedContact.toggle_opt_out = !contact.opt_out
      this.selectedContact.toggle_email_opt_out = !contact.email_opt_out
      
      this.$store.commit('livechat/selectContact', contact);

      if (manual && contact && contact.status === "Unread") {
        this.onReadMessage(contact);
      }
      this.messagePagination = { ...this.defaultMessagePagination };
      this.messages = []
      this.getMessages(false, manual)
    },

    showNewModal() {
      this.isShowNewContact = !this.isShowNewContact
    },

    handleCreate() {
      this.loadingCreate = true
      this.$store
        .dispatch('livechat/create', { phone: this.search.number.e164})
        .then((contact) => {
          this.contacts = [
            contact,
            ...this.contacts.filter((c) => c.id !== contact.id),
          ]
          this.selectContact(this.contacts[0])
          this.isShowNewContact = false
          this.newContactPhone = null
          this.search = null
          this.loadingCreate = false
          this.isShowNewModal = false
        })
        .catch(() => {
          this.loadingCreate = false
        })
    },

    handleSend() {
      this.loadingSend = true
      this.newMessage.contact_id = this.selectedContact.id
      this.newMessage.messageType = this.messageType === 0 ? 'sms' : 'email'

      this.$store
        .dispatch('livechat/sendMessage', this.newMessage)
        .then((message) => {
          this.loadingSend = false
          this.newMessage = {
            content: '',
            images: [],
          }
          this.messages.push(message)
          this.scrollTop()
          if (this.$refs.emailInput) {
            this.$refs.emailInput.reset()
          }
        })
        .catch(() => {
          this.loadingSend = false
        })
    },

    getContacts(nextPage = false) {
      if (this.contactPagination.loadMore) {
        this.loadingContacts = true
        if (nextPage) {
          this.contactPagination.page++
        }
        const queries = {
          ...this.contactPagination,
          status: this.statusFilter
        }

        this.$store
          .dispatch('livechat/getContacts', queries)
          .then((data) => {
            this.loadingContacts = false
            this.loadingMessages = false
            if (data) {
              if (!data.length && nextPage) {
                this.contactPagination.loadMore = false
              }

              if (!nextPage) {
                this.contacts = data
                if (this.contacts.length) {
                  this.selectContact(this.contacts[0])
                }
              } else {
                this.contacts = this.contacts.concat(data)
              }
            }
          })
          .catch(() => {
            // this.loadingContacts = false
          })
      }
    },

    getMessages(nextPage = false, manual = false) {
      if (this.selectedContact && this.messagePagination.loadMore) {
        this.loadingMessages = true
        if (nextPage) {
          this.messagePagination.page++
        }

        const queries = {
          contact_id: this.selectedContact.id,
          keepStatus: !manual,
          ...this.messagePagination,
        }

        this.$store
          .dispatch('livechat/getMessages', queries)
          .then((data) => {
            this.loadingMessages = false
            if (data) {
              data.sort((a, b) => a.id - b.id)
              if (!data.length && nextPage) {
                this.messagePagination.loadMore = false
              }

              if (!nextPage) {
                this.messages = data
                this.$nextTick(() => {
                  if (this.$refs.chatMessages) {
                    this.$refs.chatMessages.scrollTop =
                      this.$refs.chatMessages.scrollHeight
                  }
                })
              } else {
                this.messages = [...data, ...this.messages];
              }
            }
          })
          .catch(() => {
            // this.loadingMessages = false
          })
      }
    },

    handleSearchContact() {
      this.isLoadingCheckContact = true
      this.searchedContacts = []

      this.$store
          .dispatch('livechat/searchContacts', this.search.isValid ? this.search.number.e164 : this.search.number.input)
          .then((contacts) => {
            this.isLoadingCheckContact = false
            this.searchedContacts = contacts
          })
          .catch(() => {
            this.isLoadingCheckContact = false
          })
    },

    handlePrependContact(contact) {
      this.contacts = [
        contact,
        ...this.contacts.filter(c => c.id !== contact.id)
      ]
      this.selectContact(this.contacts[0])
      this.searchedContacts = []
      this.newContactPhone = null
      this.search = null
      this.isShowNewContact = false

      if (contact.status === 'Closed') {
        this.handleToggleClose(true)
      }
    },

    getKeywords() {
      this.$store
        .dispatch('coupon/getKeywords', { type: 'automation' })
        .then((keywords) => {
          this.keywords = keywords
        })
        .catch(() => {
        })
    },

    addKeyword(newKeyword) {
      const param = {
        name: newKeyword,
      }

      this.$store
        .dispatch('keyword/create', param)
        .then((res) => {
          if (res)
          {
            this.selectedContact.keywords.push(res.data.data)
            this.getKeywords();
          }
        })
        .catch(() => {
        })
    },

    onAddKeyword() {
      this.getKeywords()
      this.cloneContact = JSON.parse(JSON.stringify(this.selectedContact))
      this.isShowTagModal = true
    },

    handleSaveKeywords(contact) {
      this.loadingSaveKeywords = true

      this.$store
        .dispatch('contact/syncKeywords', contact)
        .then((contact) => {
          this.isShowTagModal = false
          this.loadingSaveKeywords = false
          this.selectedContact.keywords = contact.keywords
        })
        .catch(() => {
          this.loadingSaveKeywords = false
        })
    },

    removeKeyword(index) {
      this.selectedContact.keywords.splice(index, 1)
      this.handleSaveKeywords(this.selectedContact)
    },

    handleToggleClose(keep = false) {
      this.loadingToggleClose = true

      this.$store
        .dispatch('livechat/toggleOpen', this.selectedContact.id)
        .then((contact) => {
          this.loadingToggleClose = false
          this.selectedContact.status = contact.status
          if (!keep && this.statusFilter !== contact.status) {
            this.contacts = this.contacts.filter(c => c.id !== contact.id)

            if (this.contacts.length) {
              this.selectContact(this.contacts[0])
            } else {
              this.selectedContact = null
              this.messages = []
            }
          }
        })
        .catch(() => {
          this.loadingToggleClose = false
        })
    },

    handleToggleRead() {
      this.loadingToggleRead = true

      this.$store
        .dispatch('livechat/toggleRead', this.selectedContact.id)
        .then((contact) => {
          this.loadingToggleRead = false
          this.selectedContact.status = contact.status

          if (this.statusFilter && this.statusFilter !== contact.status) {
            this.contacts = this.contacts.filter(c => c.id !== contact.id)

            if (this.contacts.length) {
              this.selectContact(this.contacts[0])
            } else {
              this.selectedContact = null
              this.messages = []
            }
          }
        })
        .catch(() => {
          this.loadingToggleRead = false
        })
    },

    handleChangeFilter(status) {
      this.selectedContact = null
      this.statusFilter = status
      this.contacts = []
      this.contactPagination = { ...this.defaultContactPagination }
      this.messagePagination = { ...this.defaultMessagePagination }
      this.messages = [];
      this.getContacts()
    },

    handleSaveAttr(event, key) {
      let element = event.target
      const value = event.target.textContent

      // Value not changed
      
      if (!value && !this.selectedContact[key] || value === this.selectedContact[key]) return;

      this.loadingSaveAttr = key
      let contact = JSON.parse(JSON.stringify(this.selectedContact))
      contact[key] = value

      this.$store
        .dispatch('livechat/update', contact)
        .then((contact) => {
          this.selectedContact = contact
          this.selectedContact.toggle_opt_out = !contact.opt_out
          this.selectedContact.toggle_email_opt_out = !contact.email_opt_out
          element.textContent = contact[key]
          this.loadingSaveAttr = null
          const index = this.contacts.findIndex(item => item.id === this.selectedContact.id)
          if ( index !== -1 )
          {
            this.contacts.splice(index, 1, contact)
          }
        })
        .catch(() => {
          this.loadingSaveAttr = null
          element.textContent = this.selectedContact[key]
        })
    },

    handleZoomAttachment(item) {
      this.selectedAttachment = item
      this.isShowAttachmentModal = true
    },

    getAutomationTooltip (item) {
      let result = '';
      
      if (item.processes.length) {
        let endAt = null;
        let startAt = null;

        item.processes.forEach(process => {
          startAt = process.start_at ? process.start_at : null
          endAt = process.end_at ? process.end_at : null
        })

        if (!startAt)
          startAt = item.created_at
        
        if (startAt)
        {
          result += `Started: ${this.$options.filters.shorttime(startAt)} - `
        }

        if (endAt)
        {
          result += `Completed: ${this.$options.filters.shorttime(endAt)}`
        }

        // let completed = item.processes.filter(process => process.end_at).length
        // let percent = parseInt(completed / item.processes.length * 100);
        // result += ` - ${percent}%`
      } else {
        result += `Started: ${this.$options.filters.shorttime(item.created_at)}`
      }
      return result
    },
  },
}
</script>

<style lang="scss">
.livechat-page {
  .inbox-message-input {
    .tab-content {
      padding-top: 3px;
    }
  }
}
</style>
<style lang="scss" scoped>
.list-attrs {
  .attr-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    strong {
      color: #717171;
    }
    .attr-value {
      flex: 1 1 auto;
      padding: 8px;
      margin-left: 5px;
      margin-right: 5px;
      height: 38px;
      border-radius: 5px;
      position: relative;
      &:focus{
        border: 1px solid #e2e7f1;
        border-radius: 5px;
        outline: none;
        &:after {
          display: none;
        }
      }
      &:after {
        content: attr(data-holder);
        color: #a4a4a4;
        position: absolute;
        left: 10px;
      }
    }
    .spinner-border {
      display: none;
    }
    &.saving {
      .spinner-border {
        display: inline-block;
      }
      .attr-value {
        background: #dfdfdf;
        &:after {
          display: none;
        }
      }
    }
  }
}
.livechat-page {
  background: #fff;
  min-height: calc(100vh - 130px);
  max-height: calc(100vh - 130px);
  display: flex;
  .livechat-container {
    display: flex;
    flex: 1;
  }
  .contactlist-container {
    border-right: 2px solid#ecedf0;
    display: flex;
    flex-direction: column;
    flex: 0 0 320px;
  }

  .list-users-wrapper {
    flex: 1 1 auto;
    overflow-y: auto;
    .no-contact-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      i {
        font-size: 40px;
        color: #bad2ff;
      }
      p {
        font-size: 20px;
        font-weight: bold;
        color: #bad2ff;
      }
    }
    .list-users {
      padding: 0;
      margin: 0;
      list-style: none;
      .media-body {
        position: relative;
        .name {
          max-width: 57%;
          height: 1rem;
          overflow: hidden;
        }
        .time {
          position: absolute;
          top: 0px;
          right: 0px;
          font-size: 12px;
          color: #7e858b;
        }
      }
      li {
        position: relative;
        cursor: pointer;
        padding: 11.5px 15px 11.5px 20px;
        .online {
          background-color: #4284f4;
          width: 6px;
          height: 6px;
          display: inline-block;
          border-radius: 50%;
          position: absolute;
          left: 5px;
          top: 28px;
        }
        &:hover {
          background: rgba(68, 131, 243, 0.2);
        }
        &.selected {
          background: rgba(68, 131, 243, 0.2);
        }
      }
    }
  }

  .btn-newchat {
    border: none;
    background: transparent;
    color: #4284f4;
    padding: 0px;
    font-size: 24px;
    margin-left: 10px;
    outline: none;
  }
  .search-box {
    display: flex;
    padding: 10px;
    border-bottom: 2px solid#ecedf0;
  }
  .chat-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  .chat-main {
    width: 100%;
    display: flex;
    flex: 1;
    overflow-y: hidden;
  }
  .contact-detail {
    border-left: 1px solid #ecedf0;
    padding: 20px;
    flex: 0 0 320px;
    overflow-y: auto;
    .user-detail {
      display: flex;
      margin-bottom: 40px;
      .user-info {
        margin-left: 10px;
      }
      .name {
        font-weight: 600;
        font-size: 16px;
      }
      .phone {
        font-size: 13px;
      }
    }
  }
  .chat-conversation {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    .chat-messages {
      flex: 1 1 auto;
      overflow-y: auto;
      padding: 15px;
      .no-message-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        i {
          font-size: 40px;
          color: #bad2ff;
        }
        p {
          font-size: 20px;
          font-weight: bold;
          color: #bad2ff;
        }
      }
      .chat-media {
        border-radius: 5px;
        display: block;
        max-width: 240px;
        margin: 8px 0px;
        cursor: pointer;
      }
    }
    form {
      padding: 10px;
    }
  }
  .chat-header {
    text-align: center;
    border-bottom: 2px solid#ecedf0;
    position: relative;
    padding: 9px;
    min-height: 61px;
    position: relative;
    display: flex;
    h4 {
      font-size: 18px;
      flex: 1 1 auto;
    }
    .btn-outline-dark {
      font-size: 20px;
    }
    .btn-detail {
      border: none;
      background: transparent;
      color: #4284f4;
      padding: 0px;
      font-size: 22px;
      right: 15px;
      top: 15px;
      outline: none;
    }
  }
}

.list-keywords {
  .keyword-item {
    margin-right: 5px;
    span {
      padding: 2px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.4);
      margin-left: 4px;
    }
  }
}

.list-searched-contacts {
  position: absolute;
  background: #FFF;
  width: 360px;
  box-shadow: 1px 1px 5px #ccc;
  top: 61px;
  left: 0px;
  z-index: 1;
  .search-item {
    padding: 10px;
    border-bottom: 1px solid #ecedf0;
    user-select: none;
    cursor: pointer;
    &:hover {
      background: #ccc;
    }
    .search-detail {
      text-align: left;
      margin-left: 10px;
      p {
        margin-bottom: 0px;
        font-size: 12px;
      }
    }
  }
}
</style>